<template>
  <label class="file-select">
    <div class="select-button">
      <!-- <span v-if="value">{{ show_text_2 }} {{value.name}}</span> -->
      <span v-if="value">{{ show_text }}</span>
      <span v-else>{{ show_text }}</span>
    </div>
    <input type="file" @change="handleFileChange" ref="tfs_file_input" accept="image/*" capture='camera'/>
  </label>
</template>

<script>
import {EH_MOBILE_FUN} from '@/helper.js'
import Compressor from 'compressorjs'

export default {
  props: {
    value: File,
    show_text: String,
    show_text_2: String
  },
  data () {
    return {
      base64_txt: ''
    }
  },
  methods: {
    handleFileChange (e) {
      let file = e.target.files[0]
      let fileName = file.name
      let fileType = file.type || 'image/' + fileName.substr(fileName.lastIndexOf('.') + 1)
      this.$emit('input', e.target.files[0])
      let that = this

      let tmp = new Compressor(file, {
        quality: 0.5,
        maxHeight: 1000,
        maxWidth: 1000,
        success (result) {
          EH_MOBILE_FUN.readFile(result, function (data) {
            that.base64_txt = data
            that.$emit('inputBase64', {'base64': that.base64_txt, 'fileType': fileType})
          })
        },
        error (err) {
          console.log(err)
          alert('Sorry, Image file processing failed')
        }
      })

      console.log(tmp)
    },
    triggerFileInputClick () {
      const elem = this.$refs.tfs_file_input
      elem.click()
    }
  }
}
</script>

<style scoped>
.file-select > .select-button {
  padding: 9px 6px;

  color: white;
  background-color: #26a2ff;

  border-radius: 3px;

  text-align: center;
  font-weight: bold;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
